.App {
  align-items: center;
  text-align: center;
  max-width: 1280px;
  margin: 0 auto;
}


.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px 0;
  border-bottom: solid 3px #000000
}

.App-link {
  color: #61dafb;
}
