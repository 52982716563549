.card__wrapper{
    display: grid;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-template-rows: auto auto;
    grid-row-gap: 20px;
    grid-column-gap: 17px;
    list-style: none;
    padding-inline-start: 0px;
}

.card__wrapper-tovars{
    display: grid;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    grid-template-columns: repeat(auto-fit, minmax(10vh, 22vh));
    grid-template-rows: auto auto;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    list-style: none;
    padding-inline-start: 0px;
}