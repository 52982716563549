.fasadStrit {
    height: 400px;
    margin-right: 2em;
}

.adres {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 30px;
}