.content__grid {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 30px;
  max-width: 90vw;
}

.label{
  font-size: 60px;
  text-decoration: none;
  color: #ffffff;
}