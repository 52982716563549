.slider-wrap{
  width: 100%;
    display: flex;
    align-items: center;
}

.slider__actions {
  display: flex;
    width: 1280px;
    justify-content: space-between;
    gap: 15px;
    margin: auto 0;
    position: absolute;
    z-index: 1;
    align-items: center;
}

.slider {
  display: flex;
  gap: 15px;
}

.slide {
  flex: 1;
  flex-grow: 0.5;
  height: 30vw;
  max-height: 400px;
  border-radius: 10px;
  overflow: hidden;
  border: 0px solid white;
  box-shadow: 0 0 0 rgba(0 0 0 / 40%);
  transition: .3s;
  position: relative;
}
.slide.active {
  flex-grow: 3;
  border-width: 0.5px;
  box-shadow: 2px 4px 8px rgba(0 0 0 / 50%);
}

.slide img {
  width: 101%;
  height: 101%;
  object-fit: cover;
  object-position: center;
}

.slide__info {
  position: absolute;
  left: 20px;
  top: 30px;
  white-space: nowrap;
  opacity: 0;
  transition: 0.5s;
}
.slide.active .slide__info {
  opacity: 1;
}
.slide__label {
  font-weight: bold;
}
.slide__title {
  font-size: 26px;
  font-weight: bold;
}
