.footer{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    border-top: solid 3px #000000;
    padding-top: 30px;
    margin-top: 1em;
}

.text{
    text-decoration: none;
    font-weight: bold;
    margin: 10px 5px;
    color: #000000;
}

.link:hover{
    color: #9c9c9c;
}

.decor{
    display: flex;
    align-items: center;
    text-decoration: none;
}

.footer__icon{
    height: 20px;
    margin-right: 5px;
}