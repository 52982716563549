.popup__close-button {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 0;
    right: 0;
    background-image: url(../../../images/CloseIcon.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: cover;
    border: 0;
    cursor: pointer;
}

@media screen and (max-width: 550px) {
    .popup__close-button {
        width: 20px;
        height: 20px;
      }
  }