.icon-button {
  --size: 40px;
  min-width: var(--size);
  height: var(--size);
  cursor: pointer;
}

.icon-button.left,
.icon-button.left.disable {
  background: url('../../Slider/assets/icons/arrow_left.svg') center / 100% 100% no-repeat;
}
.icon-button.left:hover {
  background: url('../../Slider/assets/icons/arrow_left_active.svg') center / 100% 100% no-repeat;
}
.icon-button.right,
.icon-button.right.disable {
  background: url('../../Slider/assets/icons/arrow_right.svg') center / 100% 100% no-repeat;
}
.icon-button.right:hover {
  background: url('../../Slider/assets/icons/arrow_right_active.svg') center / 100% 100% no-repeat;
}
.icon-button.left.disable,
.icon-button.right.disable {
  pointer-events: none;
}
