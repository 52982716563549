    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2em;
        max-width: 100%;
        margin: 0 auto 1em;
        background-color: rgb(0, 0, 0);
        border-bottom: 0.5em solid rgba(212, 181, 3, 0.7);
        z-index: 2;
    }
    
    @media screen and (max-width: 900px) {
        .header {
            padding: 28px 0 40px;
          margin: 0 auto 34px;
          }
      } 

      .header__logo {
        width: 100px;
        height: auto;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
    }
    
    @media screen and (max-width: 550px) {
        .header__logo {
            width: 103px;
            height: 24px;
          }
      } 


      .header__text{
        text-decoration: none;
    font-weight: normal;
    font-size: small;
    margin: 5px 5px;
    color: #d4b503;
      }

      .header__decor{
        display: flex;
    align-items: center;
    text-decoration: none;
      }

      .header__adres{
        display: flex;
        flex-direction: column;
    align-items: center;
      }