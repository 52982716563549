.menu__link{
    color: #d4b503;
    margin: 1em;
    text-decoration: none;
    font-size: small;
    cursor: pointer;
    z-index: 1;
}

.menu__link_active{
    color: rgb(255, 255, 255);
   background-color: none;
}

.menu{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    border: auto 20px;
}

  /* Контейнер <div> - необходим для размещения выпадающего содержимого */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Выпадающее содержимое (скрыто по умолчанию) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #000000;
    min-width: 140px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    text-align: left;
  }
  
  /* Ссылки внутри выпадающего списка */
  .dropdown-link {
    color: rgb(255, 255, 255);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Изменение цвета выпадающих ссылок при наведении курсора */
  .dropdown-content a:hover {background-color: #000000;}
  
  /* Показать выпадающее меню при наведении курсора */
  .dropdown:hover .dropdown-content {display: flex; justify-content: space-around; flex-direction: column; justify-content: center}
  
  /* Изменение цвета фона кнопки раскрывающегося списка при отображении содержимого раскрывающегося списка */
  .dropdown:hover .dropbtn {background-color: #ffffff;}