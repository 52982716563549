.card__body{
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 100%;
    box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding-bottom: 8px;
}

.card__body_one{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    
    padding-bottom: 8px;
}

.card__body:hover{
    box-shadow: 0px 5px 10px 3px rgba(0, 0, 0, 0.3);
    transform: translateY(-5px);
  }

.card__country, .card__collection, .card__name, .card__size{
    margin: 2px 10px 2px;
    text-align: left;
    color: black;
}

.card__img{
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
}
.card__img_card{
    width: 500px;
    height: 100%;
    object-fit: cover;
    
}

.card__collection{
    font-size: larger;
    font-weight: bold;
    margin-bottom: 12px;
    text-decoration: none;
    }

.card__name{
    font-size: medium;
    text-decoration: none;
   }

.card__country{
    font-size: medium;
    text-decoration: none;
    }

.card__size{
    font-size: small;
}

.card__conteiner{
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 100%;
    max-height: 100%;
    
    padding-bottom: 8px;
    justify-content: space-between
}

.card__page{
    width: 100%;
    height: 100%;
    padding: 0;
    align-content: center;
}

.card__img_interiors{
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}

.card__img_tovar{
    width: auto;
    height: 14vh;
    object-fit: contain;
    
}

.card__img-interior{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}

.card__img-interiors{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 160px));
    grid-template-rows: repeat(auto-fit, minmax(80px, 160px));
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    list-style: none;
    margin: 0;
    width: 700px;
    height: 100%;
    padding: 0 10px;
}

.card__img_tovars{
    width: 100%;
    height: 100%;
}