.popup {
    visibility: hidden;
    transition: opacity .4s ease-in, visibility .4s ease-out;
    opacity: 0;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #00000080;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}